export const LogsSortData = [
    { 
      label: 'Sort By',
      value: ''
    },
    {
      label: 'Keyword',
      value: '1',
    },
    {
      label: 'Search Count',
      value: '2',
    }
  ];

  export const SearchBy = [
    { 
      label: 'Search By',
      value: ''
    },
    {
      label: 'Newest',
      value: 'desc',
    },
    {
      label: 'Oldest',
      value: 'asc',
    }
  ];