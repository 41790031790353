import React, { useEffect, useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNewParameters,
  getObjectSubset,
  getParameters,
  handleTheImageOpen,
} from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import {
  imagesForShootsListings,
  updateWaterMark,
} from '../../Services/imageApis';
import { imageListingItemsSelector } from '../../redux/image';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AllFilters from '../Order/AllFilters';
import { WatermarkData, SortBy, OrderBy } from '../../data/imageData';
import EditImageDetailModelNewShoot from '../Modals/EditImageDetailModelNewShoot';
import ViewImage from '../Modals/ViewImage ';

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
        onClick={() => {}}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
    onClick: handleTheImageOpen,
    numeric: 'center',
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        onClick={() => {}}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Rank1',
    id: 'rank1',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Sold Count',
    id: 'sold_count',
    format: (columnData) => (
      <span
        style={{ display: 'block', textAlign: 'center', whiteSpace: 'nowrap' }}
      >
        {columnData}
      </span>
    ),
    nextLine: false,
    numeric: 'center',
  },
];

function ShootIDListingPages({ showCheckbox, setShowCheckbox }) {
  const limitOptions = [10, 50, 100, 250];
  // const limit = 10;
  const [limit, setLimit] = useState(limitOptions[0]);
  const { id } = useParams();
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, shootImagesIDList } = useSelector(imageListingItemsSelector);
  const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
  const [imageWatermark, setImageWatermark] = useState({});
  const [confirmImageWatermark, setConfirmImageWatermark] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [imageEditModal, setImageEditModal] = useState(false);
  const [editImageData, setEditImageData] = useState({});
  const [imageShowModel, setImageShowModel] = useState(false);
  const [imageOpenData, setImageOpenData] = useState(null);

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Sort By',
      id: 'sort_by',
      type: 'select',
      linkedWith: null,
      value: 'sold_count',
      onChange: (e, index) => {
        const sortBy = e.target.value;
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = sortBy;
          return newState;
        });
        handleFilter(
          {},
          {},
          { sort_by: sortBy, order_by: filtersArray[1].value },
        );
      },
      style: {},
      dataArr: SortBy,
      props: {},
      isVisible: true,
    },
    {
      label: 'Order By',
      id: 'order_by',
      type: 'select',
      linkedWith: null,
      value: 'desc',
      onChange: (e, index) => {
        const orderBy = e.target.value;
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = orderBy;
          return newState;
        });
        handleFilter(
          {},
          {},
          { order_by: orderBy, sort_by: filtersArray[0].value },
        );
      },
      style: {},
      dataArr: OrderBy,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const getListing = async (params = {}) => {
    const payload = {
      page: String(page),
      limit: limit,
      shoot_id: id,
      sort_by: filtersArray[0].value,
      order_by: filtersArray[1].value,
      ...allParams,
      ...params,
    };
    dispatch(imagesForShootsListings(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    const updatedParams = {
      page: String(1),
      sort_by: filtersArray[0].value,
      order_by: filtersArray[1].value,
      ...dataWithValue,
    };
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    // setShowCheckbox(false);
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      return newState;
    });
  };
  useEffect(() => {
    getListing();
  }, [page, limit]);
  const handleCofirmModalOpen = (value) => {
    setConfirmImageWatermark(value);
  };
  const handleConfirmWatermark = async (value) => {
    if (value && imageWatermark) {
      const resp = await updateWaterMark({
        image_id: imageWatermark,
        watermark: 0,
      });
      if (resp) {
        getListing({ page: '1' });
        setPage(1);
        handleClearSelectedCheckbox(true);
      }
    } else {
      setImageWatermark(null);
    }
  };
  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);
  const handleBulkUpdate = async (data, resetState) => {
    let stateSelected = shootImagesIDList.items.rows.filter(
      (item) => item.watermark == 1,
    );
    let userSelected = stateSelected.map((item) => item.Name);
    const intersection = userSelected.filter((element) =>
      data.includes(element),
    );
    handleCofirmModalOpen(true);
    setImageWatermark(intersection);
  };
  const handleClearSelectedCheckbox = (value = false) => {
    setClearSelectedCheckbox(value || false);
  };

  const handleModal = (value = false) => {
    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  const handleImageModal = (value = false) => {
    setImageShowModel(value);
  };
  const imageActionBody = (data) => {
    setImageOpenData(data);
    setImageShowModel(true);
  };

  //making data format for table input
  useEffect(() => {
    if (shootImagesIDList?.items?.rows?.length >= 0) {
      let data = shootImagesIDList?.items?.rows?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          sno: item.sno,
          name: item.Name,
          rank: item?.rank,
          rank1: item.rank1,
          suspendate: item?.suspendate,
          watermark: item?.watermark,
          sold_count: item?.sold_count,
          image_type: item?.type,
          pricing: item?.pricing,
        };
      });
      setTableListing(data);
    }
  }, [shootImagesIDList?.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleModal(true);
              setEditImageData(data);
            }}
          >
            Edit
          </CustomButton>
        </div>
      </>
    );
  };

  const handleSubmitFilters = () => {
    getListing({
      page: String(1),
      sort_by: filtersArray[0].value,
      order_by: filtersArray[1].value,
    });
    setAllParams({
      page: String(1),
      sort_by: filtersArray[0].value,
      order_by: filtersArray[1].value,
    });
  };

  return (
    <div>
      <Divder spacing={1} />
      <MatTable
        loading={shootImagesIDList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        imageActionBody={imageActionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={limitOptions}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={shootImagesIDList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        columnWiseData={[
          {
            id: 'name' + 'watermark',
            value: handleBulkUpdate,
          },
        ]}
        pagination={true}
        checkboxEnable={showCheckbox}
        customCheckboxDataKey={'name'}
        onTableToolbarRightClick={handleBulkUpdate}
        clearSelectedCheckbox={clearSelectedCheckbox}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox} // requred to clear filter and refresh state after api call
        onTableToolbarRightSection={
          <div>
            <CustomButton
              sx={(theme) => ({
                fontSize: '12px',
                whiteSpace: 'nowrap',
                bgcolor: 'buttonSecondary.main',
                color: 'buttonSecondary.contrastText',
                [theme.breakpoints.down('md')]: {
                  flex: 1,
                },
              })}
              onClick={handleSubmitFilters}
            >
              Submit Filters
            </CustomButton>

            <CustomButton
              sx={(theme) => ({
                fontSize: '12px',
                whiteSpace: 'nowrap',
                bgcolor: 'buttonSecondary.main',
                color: 'buttonSecondary.contrastText',
                [theme.breakpoints.down('md')]: {
                  flex: 1,
                },
              })}
            >
              Remove Watermark
            </CustomButton>
          </div>
        }
        toolbarEnable={true}
        filtersUrlData={allParams || {}}
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      <EditImageDetailModelNewShoot
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
      />
      <>
        {imageShowModel && (
          <ViewImage
            open={imageShowModel}
            handleOpen={handleImageModal}
            data={imageOpenData}
          />
        )}
      </>

      {confirmImageWatermark && (
        <ConfirmationModal
          open={confirmImageWatermark}
          handleConfirm={handleConfirmWatermark}
          setOpen={handleCofirmModalOpen}
        />
      )}
    </div>
  );
}

export default ShootIDListingPages;
