export const ImageData = {
  tabs: [
    {
      label: 'All Images',
      id: 1,
    },
    {
      label: 'Star Images',
      id: 2,
    },
    {
      label: 'Exclusive Images',
      id: 3,
    },
    {
      label: 'Sold Images',
      id: 4,
    },
    {
      label: 'Sold Images By Group ID',
      id: 6,
    },
    {
      label: 'Suspended Images',
      id: 7,
    },
    {
      label: 'Shoots',
      id: 8,
    },
    {
      label: 'Highest Sold Images/Shoots',
      id: 9,
    },
    {
      label: 'Most Viewed',
      id: 10,
    },
  ],
};
export const ImageSelectData = {
  imageType: [
    {
      value: 'I',
      label: 'I',
    },
    {
      value: 'S',
      label: 'S',
    },
  ],
};
export const WatermarkData = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
];
  export const SearchBy = [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Image ID',
      value: 'image_id',
    },
    {
      label: 'Shoot ID',
      value: 'shoot_id',
    }  
  ];
  export const SearchByPending = [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Image ID',
      value: 'image_id',
    },
    {
      label: 'Shoot ID',
      value: 'shoot_id',
    }  
  ];

  export const SortBy = [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Sold Count',
      value: 'sold_count',
    },
    {
      label: 'Rank1',
      value: 'rank1',
    }  
  ];

  export const OrderBy = [
    {
      label: 'DESC',
      value: 'desc',
    },
    {
      label: 'ASC',
      value: 'asc',
    }
  ];
